import React from 'react';
import './ComponentsPage.scss';


const ComponentsPage: React.FC = () => {
    return (
        <div className="components-page">
            <h1>Components</h1>
            <p>This is the components page content.</p>
            <div>

            </div>
        </div>
    );
};

export default ComponentsPage;
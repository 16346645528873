import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from './components/Layout/Layout';
import ComponentsPage from './pages/ComponentsPage/ComponentsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><ComponentsPage /></Layout>} />

      </Routes>
    </Router>
  );
}

export default App;

import { ReactNode, useState } from 'react';
//import { Nav } from "@precooked/react-nav";
import './Layout.scss';


interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

    return (
        <>
            <div className='header'>
                {/* <Nav
                    items={[
                        { label: 'Mis Snaps', link: '/' },
                        { label: 'Mi Actividad', link: '/my-activity' },
                        { label: 'Mi biblioteca', link: '/my-ring-binders' }
                    ]}
                    activeItemStyles={{

                    }}
                /> */}

            </div>
            <div className='body'>
                {children}
            </div>

        </>
    );
};

export default Layout;
